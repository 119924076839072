<template>
  <div class="modal-md rounded-lg bg-white p-4 md:p-6">
    <!-- create and image upload button with a progress bar for the upload -->

    <div v-if="catDetails" class="flex flex-col">
      <div class="input-group mx-auto">
        <label>What is your name?</label>
        <input required v-model="catDetails.name" type="text" />
      </div>
      <div class="input-group mx-auto">
        <label>What gender are you?</label>
        <div class="relative block">
          <div
            class="pointer-events-none absolute right-0 p-3 pr-4 text-lg text-gray-700"
          >
            <font-awesome-icon :icon="['far', 'chevron-down']" />
          </div>
          <select v-model="catDetails.gender">
            <option value="" class="text-gray-400" disabled selected>
              Select an option
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Rather not say</option>
          </select>
        </div>
      </div>
      <div class="input-group mx-auto">
        <label>What tribe do you belong to?</label>
        <input
          required
          v-model="catDetails.tribe"
          type="text"
          placeholder="e.g. Ginger Tabby or Maine Coon"
        />
      </div>
      <div class="input-group mx-auto">
        <label>Are you an adult cat or kitten?</label>
        <div class="flex justify-center text-gray-700">
          <div class="flex w-full space-x-2 rounded-lg bg-gray-200 p-2">
            <div
              @click="isKitten = false"
              class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
              :class="{
                'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                  !isKitten
              }"
            >
              <div
                class="absolute left-1 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                v-if="!isKitten"
              >
                <font-awesome-icon
                  class="text-teal-500"
                  v-if="!isKitten"
                  :icon="['far', 'check']"
                />
              </div>
              <div class="mx-auto">Adult</div>
            </div>
            <div
              @click="isKitten = true"
              class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
              :class="{
                'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                  isKitten
              }"
            >
              <div
                class="absolute left-1 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                v-if="isKitten"
              >
                <font-awesome-icon
                  class="text-teal-500"
                  v-if="isKitten"
                  :icon="['far', 'check']"
                />
              </div>
              <div class="mx-auto">Kitten</div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-group mx-auto">
        <label class="space-x-2"
          ><!-- add the camera emoji -->

          <span>Show us your best side <span>📷</span> ...</span></label
        >

        <upload class="mt-4" :cat-details="catDetails" />
      </div>
    </div>
    <form @submit.prevent="updateCat" novalidate="true">
      <loading-button
        :is-loading="isLoading"
        :is-enabled="true"
        :is-full="true"
        class="mt-4"
        label="Update"
      />
    </form>
    <div class="mt-6 text-center text-gray-600">
      <a @click="closeModal">Cancel</a>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';

import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, findIndex } from 'lodash/fp';
import Upload from './Upload.vue';
export default {
  props: ['data'],
  components: {
    LoadingButton,
    Upload
  },
  data() {
    return {
      isLoading: false,
      catDetails: undefined,
      isKitten: undefined
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    }),
    cats() {
      return this.subscription.items;
    },
    catIndex() {
      return findIndex(['id', this.catDetails.id], this.cats);
    }
  },
  methods: {
    ...mapActions({
      uploadImage: 'uploadImage',
      removeImage: 'removeImage'
    }),

    closeModal: function () {
      this.$parent.$emit('make-close');
    },
    updateCat() {
      let catData = this.cats;
      this.catDetails.tribe = this.catDetails.tribe
        ? this.catDetails.tribe
        : 'Unknown';
      this.catDetails.type = this.isKitten ? 'kitten' : 'adult';

      catData.splice(this.catIndex, 1, this.catDetails);
      catData.forEach((c) => {
        c.dob = '01/01/1970';
      });
      this.$store
        .dispatch('updateCatDetails', catData)
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  mounted() {
    this.catDetails = cloneDeep(this.data.cat);
    this.catDetails.tribe =
      this.catDetails.tribe === 'Unknown' ? '' : this.catDetails.tribe;
    this.isKitten = this.data.cat.type === 'kitten';
    this.$store.dispatch('sendMpEvent', {
      event: `Edit cat details - View`,
      properties: {
        location: this.$route.name,
        catId: this.data.cat.id,
        cat: this.data.cat.name,
        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
